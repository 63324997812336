import { useQuery } from 'react-query'
import { ReportService } from '@/utils'

export interface Monitor {
  dataKey: string
  whiteListCnt: number
  nftCnt: number
  cnt: number
}

export interface TransactionDetail {
  dataKey: string
  recordCnt: number
  avgPrice: number
  nftCnt: number
}

export interface CollectionDetail {
  avg3DayTrad: number
  avg7DayTrad: number
  avg15DayTrad: number
  avg30DayTrad: number
  avg90DayTrad: number
  avg180DayTrad: number
  avgHoldings: number
  buyerCnt: number
  cnt: number
  cnt1Day: number
  collectionValuation: number
  dataKey: string
  med1DayPrice: number
  med3DayPrice: number
  med7DayPrice: number
  med15DayPrice: number
  med30DayPrice: number
  med90DayPrice: number
  med180DayPrice: number
  turnoverRate: number
}

export interface ValuationDetail {
  dataKey: string
  cnt: number
  nftCnt: number
  avgAiValuation: number
  avgSimpleValuation: number
}

export const nftTransactionRecord = () => {
  return useQuery<Monitor>(
    ['NFT_TRANSACTION_RECORD'],
    () => {
      return ReportService.get('/monitor/nftTransactionRecord') as any
    }
  )
}

export const transactionDetail = (data: any) => {
  return useQuery<Array<TransactionDetail>>(
    ['TRANSACTION_DETAIL', data],
    () => {
      if (!data) return undefined

      return ReportService.post('/monitor/transactionDetail', data) as any
    }
  )
}


export const featNftRecord = () => {
  return useQuery<Monitor>(
    ['FEAT_NFT_RECORD'],
    () => {
      return ReportService.get('/monitor/featNftRecord') as any
    }
  )
}

export const nftDetail = (data: any) => {
  return useQuery<Array<TransactionDetail>>(
    ['NFT_DETAIL', data],
    () => {
      if (!data) return undefined

      return ReportService.post('/monitor/nftDetail', data) as any
    }
  )
}
export const featNftCollectionRecord = () => {
  return useQuery<Monitor>(
    ['FEAT_NFT_COLLECTION_RECORD'],
    () => {
      return ReportService.get('/monitor/featNftCollectionRecord') as any
    }
  )
}

export const collectionDetail = (data: any) => {
  return useQuery<Array<CollectionDetail>>(
    ['COLLECTION_DETAIL', data],
    () => {
      if (!data) return undefined

      return ReportService.post('/monitor/collectionDetail', data) as any
    }
  )
}
export const aiNftValuationRecord = () => {
  return useQuery<Monitor>(
    ['AI_NFT_VALUATION_RECORD'],
    () => {
      return ReportService.get('/monitor/aiNftValuationRecord') as any
    }
  )
}

export const valuationDetail = (data: any) => {
  return useQuery<Array<ValuationDetail>>(
    ['VALUATION_DETAIL', data],
    () => {
      if (!data) return undefined

      return ReportService.post('/monitor/valuationDetail', data) as any
    }
  )
}
