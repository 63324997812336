import { useQuery } from 'react-query'
import { ReportService } from '@/utils'

export type Whitelist = {
  id: string
  metaVerified: string
  nftName: string
  symbol: string
  priceType: string
}

export const useWhitelistQuery = () => {
  return useQuery<Whitelist[]>(['WHITELIST'], () => {
    return ReportService.post('/symbol/whitelist') as any
  })
}
