import React, { useCallback, useMemo, useState } from 'react'
import { EChartsOption } from 'echarts'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import ReactECharts from 'echarts-for-react'
import { Punk } from '@/hooks/queries/punkQuery'


export const PunkAnalytics: React.FC = () => {
  const [tokenId, setTokenId] = useState<number>(0)
  const [form, setForm] = useState({ tokenId: 1 })
  const { data: data, isFetching } = Punk(form)
  const handleSearch = useCallback(() => {
    setForm({ tokenId })
  }, [tokenId])
  const option: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'category',
        data: data?.map(p => p.dataKey),
      },
      yAxis: {
        type: 'value'
      },
      tooltip: {
        trigger: 'axis',
      },
      series: [
        {
          type: 'line',
          name: 'aiValuation',
          data: data?.map(o => o.aiValuation),
          smooth: true
        },
        {
          type: 'line',
          name: 'simpleValuation',
          data: data?.map(o => o.simpleValuation),
          lineStyle: {
            opacity: .5
          },
          stack: 'confidence-band',
          symbol: 'none',
          smooth: true
        },
        {
          type: 'line',
          name: 'deepValuation',
          data: data?.map(o => o.deepValuation),
        },
        {
          type: 'scatter',
          name: 'totalAmount',
          data: data?.map(o => o.totalAmount),
          encode: {
            x: 'dataKey',
            y: 'totalAmount'
          }
        },
      ],
      legend: {
        data: ['aiValuation', 'simpleValuation', 'deepValuation', 'totalAmount']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [data])
  return (
    <Box sx={{ width: '1200px', height: '1030px' }}>
      <Typography variant={'h5'}>PUNK:</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'end' }} m={'32px 0'}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <TextField id="alpha" label="tokenId" variant="outlined" onChange={e => setTokenId(Number(e.target.value))} />
          <Button onClick={handleSearch} disabled={isFetching}>
            { isFetching ? 'loading...' : 'Search' }
          </Button>
        </Box>
      </Box>
      <ReactECharts
        option={option}
        opts={{
          height: 800
        }}
      />
    </Box> 
  )
}
