import { Box, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import { SolanaAnalytics } from '@/sections/SolanaAnalytics'
import { MoonbeamAnalytics } from '@/sections/MoonbeamAnalytics'
import { PunkAnalytics } from '@/sections/PunkAnalytics'
import { Monitor } from '@/sections/Monitor'
import './App.css'

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  )
}

export const App = () => {
  const [tab, setTab] = useState(0)

  return (
    <div className="App">
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '48px' }}>
        <Tabs value={tab} onChange={(e, v) => setTab(v)} aria-label="basic tabs example">
          <Tab label="Solana" id={'solana'}  />
          <Tab label="Moonbeam" id={'moonbeam'}  />
          <Tab label="Punk" id={'punk'}  />
          <Tab label="Monitor" id={'monitor'}  />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <SolanaAnalytics />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <MoonbeamAnalytics />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <PunkAnalytics />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <Monitor />
      </TabPanel>
    </div>
  )
}
