import { useQuery } from 'react-query'
import { ReportService } from '@/utils'

export const useQueryMoonbeamWhitelists = () => {
  return useQuery(
    ['MOONBEAM_WHITELISTS'],
    () => {
      return ReportService.get('/valuation/moonbeam/whitelist') as any
    }
  )
}
