import { useQuery } from 'react-query'
import { ReportService } from '@/utils'

export interface MoonbeamValuation {
  contractAddress: string
  tokenId: number
  name: string
  collectionSymbol: string
  marketType: string
  price: number
  nftValuation: number
  timeStamp: number
  createTime: string
  rarity: number

  nftCollectionMedianValuation: Record<string, any>
}

export const useQueryMoonbeamValuations = (data: any) => {
  return useQuery<Array<MoonbeamValuation>>(
    ['MOONBEAM_TRANSACTIONS', data],
    () => {
      if (!data) return undefined

      return ReportService.post('/valuation/moonbeam/transaction', data) as any
    }
  )
}
