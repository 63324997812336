import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { App } from './App'
import { QueryClient, QueryClientProvider } from 'react-query'
import { CollectionProvider } from '@/CollectionContext'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false
    }
  }
})

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CollectionProvider>
        <App />
      </CollectionProvider>
    </QueryClientProvider>
  </React.StrictMode>
)
