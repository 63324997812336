import { useQuery } from 'react-query'
import { ReportService } from '@/utils'
import { ValuationDeviationMetric } from './types'

export type DailyTrendOfValuationDeviationRequest = {
  id?: string
  metric: ValuationDeviationMetric
  startTime: number
  endTime: number
}

export type ValuationDeviationByDailyTrend = {
  avg: number
  min: number
  max: number
  transDay: number
  slugSymbol: string
  sales?: number
}

export const useValuationDeviationByDailyTrendQuery = (data: DailyTrendOfValuationDeviationRequest) => {
  return useQuery<Array<ValuationDeviationByDailyTrend>>(
    ['VALUATION_DEVIATION_BY_DAILY_TREND', data],
    () => {
      if (!data.id) return undefined

      return ReportService.post('/valuation/deviation/symbol/trend', data) as any
    }
  )
}
