import { useQuery } from 'react-query'
import { ReportService } from '@/utils'

export type ValuationDeviationBasedOnTransactionRequest = {
  id?: string
  startTime: number
  endTime: number
  symbol?: string
  alpha?: number
}
export type HistoryValuationDTO = {
  transactionTime: number
  valuationDeviation: number
}

export type ValuationDeviationBasedOnTransaction = {
  salePrice: number
  valuation: number
  transactionSignature: string
  transactionTime: number
  tokenAddress: string
  metaVerified: string
  symbol: string
  name: string
  program: string
  transactionDate: string

}

export type ValuationV2DeviationBasedOnTransaction = {
  salePrice: number
  valuation: number
  transactionSignature: string
  transactionTime: number
  tokenAddress: string
  metaVerified: string
  symbol: string
  name: string
  program: string
  transactionDate: string
  valuationV1: number
  valuationV2: number
  rarity: number
  rarityMedian: number
  collectionValuation: number
  bias: number
  strategyBias: number
  history: Array<HistoryValuationDTO>
}

export type AiValuationDeviationBasedOnTransaction = {
  timestamp: number
  transactionDate: string
  whiteListId: string
  nftName: string
  totalAmount: number
  simpleValuation: number
  aiValuation: number
  strategyValue1: number
  strategyValue2: number
}


export const useValuationDeviationBasedOnTransactionQuery = (data: ValuationDeviationBasedOnTransactionRequest) => {
  return useQuery<ValuationDeviationBasedOnTransaction[]>(
    ['VALUATION_DEVIATION_BASED_ON_TRANSACTION', data],
    () => {
      if (!data.id) return undefined

      return ReportService.post('/valuation/deviation/transaction', data) as any
    }
  )
}


export const useAllSalePricesQuery = (data: ValuationDeviationBasedOnTransactionRequest) => {

  return useQuery<number[]>(
    ['ALL_SALEPRICE', data],
    () => {
      if (!data.id) return undefined

      return ReportService.post('/valuation/deviation/allPrice', data) as any
    }
  )
}

export const useAllValuationsQuery = (data: ValuationDeviationBasedOnTransactionRequest) => {
  return useQuery<number[]>(
    ['ALL_VALUATIONS', data],
    () => {
      if (!data.id) return

      return ReportService.post('/valuation/deviation/symbol/range', data) as any
    }
  )
}


export const SimpleValuationDeviationBasedOnTransactionQuery = (data: ValuationDeviationBasedOnTransactionRequest) => {
  return useQuery<ValuationDeviationBasedOnTransaction[]>(
    ['SIMPLE_VALUATION_DEVIATION_ON_TRANSACTION', data],
    () => {
      if (!data.id) return undefined

      return ReportService.post('/valuation/solana/transaction', data) as any
    }
  )
}

export const AiValuationDeviationBasedOnTransactionQuery = (data: ValuationDeviationBasedOnTransactionRequest) => {
  return useQuery<AiValuationDeviationBasedOnTransaction[]>(
    ['AI_VALUATION_DEVIATION_ON_TRANSACTION', data],
    () => {
      if (!data.id) return undefined

      return ReportService.post('/valuation/solana/transactionAi', data) as any
    }
  )
}

export const AiValuationDeviationBasedOnTransactionQuery2 = (data: ValuationDeviationBasedOnTransactionRequest) => {
  return useQuery<AiValuationDeviationBasedOnTransaction[]>(
    ['AI_VALUATION2_DEVIATION_ON_TRANSACTION', data],
    () => {
      if (!data.id) return undefined

      return ReportService.post('/valuation/solana/transactionAi2', data) as any
    }
  )
}

export const SimpleValuationDeviationV2BasedOnTransactionQuery = (data: ValuationDeviationBasedOnTransactionRequest) => {
  return useQuery<ValuationV2DeviationBasedOnTransaction[]>(
    ['SIMPLE_VALUATION_DEVIATION_ON_TRANSACTION_V2', data],
    () => {
      if (!data.id) return undefined

      return ReportService.post('/valuation/solana/transactionV2', data) as any
    }
  )
}

export type AiValuationDeviation = {
  name: string
  count: number
}

export const AiValuationDeviationQuery = (data: ValuationDeviationBasedOnTransactionRequest) => {
  return useQuery<number[]>(
    ['AI_VALUATION_DEVIATION', data],
    () => {
      if (!data.id) return undefined

      return ReportService.post('/valuation/deviation/deviation', data) as any
    }
  )
}

export const SimpleValuationDeviationQuery = (data: ValuationDeviationBasedOnTransactionRequest) => {
  return useQuery<number[]>(
    ['AI_VALUATION_DEVIATION', data],
    () => {
      if (!data.id) return undefined

      return ReportService.post('/valuation/solana/deviation', data) as any
    }
  )
}

export const SimpleValuationV2DeviationQuery = (data: ValuationDeviationBasedOnTransactionRequest) => {
  return useQuery<number[]>(
    ['AI_VALUATION_DEVIATION_V2', data],
    () => {
      if (!data.id) return undefined

      return ReportService.post('/valuation/solana/deviationV2', data) as any
    }
  )
}


export const SimpleValuationDeviationV3BasedOnTransactionQuery = (data: ValuationDeviationBasedOnTransactionRequest) => {
  return useQuery<ValuationV2DeviationBasedOnTransaction[]>(
    ['SIMPLE_VALUATION_DEVIATION_ON_TRANSACTION_V3', data],
    () => {
      if (!data.id) return undefined

      return ReportService.post('/valuation/solana/transactionV3', data) as any
    }
  )
}

export const SimpleValuationV3DeviationQuery = (data: ValuationDeviationBasedOnTransactionRequest) => {
  return useQuery<number[]>(
    ['AI_VALUATION_DEVIATION_V3', data],
    () => {
      if (!data.id) return undefined

      return ReportService.post('/valuation/solana/deviationV3', data) as any
    }
  )
}

