import React, { useState } from 'react'

const CollectionContext = React.createContext({
  collection: '',
  setCollection: (_v: string) => {},
  symbol: '',
  setSymbol: (_v: string) => {}
})

const CollectionProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [collection, setCollection] = useState('')
  const [symbol, setSymbol] = useState('')
  return (
    <CollectionContext.Provider
      value={{ collection, setCollection, symbol, setSymbol }}
    >
      {children}
    </CollectionContext.Provider>
  )
}

export { CollectionContext, CollectionProvider }
