
import { useQuery } from 'react-query'
import { ReportService } from '@/utils'
import { ValuationDeviationByDailyTrend } from '@/hooks/queries/useValuationDeviationByDailyTrendQuery'

export const useAllValuationDeviationsQuery = () => {
  return useQuery<ValuationDeviationByDailyTrend[]>(
    ['ALL_VALUATION_DEVIATIONS'],
    () => {
      return ReportService.post('/valuation/deviation/symbol/allTrend', {}) as any
    }
  )
}

