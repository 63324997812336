import { useQuery } from 'react-query'
import { ReportService } from '@/utils'

export interface PunkDO {
  dataKey: string
  deepValuation: number
  simpleValuation: number
  aiValuation: number
  totalAmount: number
}

export interface Amount {
  dataKey: string
  totalAmount: number
}

export interface Punk {
  amount: Array<Amount>
  punk: Array<PunkDO>
}

export const Punk = (data: any) => {
  return useQuery<Array<PunkDO>>(
    ['PUNK', data],
    () => {
      if (!data) return undefined

      return ReportService.post('/punk', data) as any
    }
  )
}
