import React, { useCallback, useMemo, useState } from 'react'
import { MoonbeamValuation, useQueryMoonbeamValuations } from '@/hooks/queries/useQueryMoonbeamValuations'
import { useQueryMoonbeamWhitelists } from '@/hooks/queries/useQueryMoonbeamWhitelists'
import { EChartsOption } from 'echarts'
import camelcase from 'camelcase'
import dayjs from 'dayjs'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import ReactECharts from 'echarts-for-react'

export const MoonbeamAnalytics: React.FC = () => {
  const [contractAddress, setContractAddress] = useState('')
  const [alpha, setAlpha] = useState<number>(0)

  const [form, setForm] = useState({ contractAddress: '', alpha: 0 })

  const { data: valuations, isFetching } = useQueryMoonbeamValuations(form)
  const { data: whitelists } = useQueryMoonbeamWhitelists()

  const maxSalePrice = useMemo(() => valuations?.map(o => o.price).reduce((max, next) => Math.max(max, next), 0) || 0, [valuations])

  const linearData = useMemo(() => new Array(100).fill(undefined).map((_, i) => ([(i / 100 * maxSalePrice).toFixed(3), (i / 100 * maxSalePrice).toFixed(2)])), [maxSalePrice])

  const mape = React.useMemo(() => {
    return valuations ? valuations.reduce((r, cur) => (r + Math.abs((cur.nftValuation - cur.price) / cur.price)), 0) / valuations.length : undefined
  }, [valuations])

  const handleSearch = useCallback(() => {
    setForm({ alpha, contractAddress })
  }, [alpha, contractAddress])

  const option: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'value',
        name: 'Sale Price'
      },
      yAxis: {
        type: 'value',
        name: 'Valuation'
      },
      tooltip: {
        trigger: 'item',
        hideDelay: 250,
        enterable: true,
        position: 'top',
        formatter: (props: any) => {
          if (props.componentSubType === 'scatter') {
            const data: MoonbeamValuation = props.data

            return `
              <div style="display: flex; flex-direction: column; align-items: start;">
                <div><b>NFT Name: </b>${data.name}</div>
                <div><b>Token ID: </b>${data.tokenId}</div>
                <br>
                
                <div><b>Sale Price: </b>${data.price}</div>
                <div><b>Valuation: </b>${data.nftValuation}</div>
                <div>
                  <b>Deviation: </b>
                  <span style="color: ${data.nftValuation < data.nftValuation ? 'green' : 'red'}">${((data.nftValuation - data.price) / data.price * 100).toFixed(2)}%</span>
                </div>
                <br>
                
      ${
      Object.entries(data)
        .filter(([key]) => !['price', 'nftValuation', 'name', 'tokenId', 'timeStamp', 'nftCollectionMedianValuation'].includes(key))
        .map(([key, value]) => `
          <div><b>${camelcase(key)}: </b>${value}</div>
        `).join('')
      }
                
                <br><br>
               
                <div><b>nftCollectionMedianValuation</b>:</div>
                <ul>
      ${
      Object.entries(data.nftCollectionMedianValuation)
        .filter(([, value]) => !!value)
        .map(([key, value]) => `
          <li><b>${camelcase(key)}: </b>${value}</li>
        `).join('')
      }
                </ul>
                
                <div><b>Transaction Time: </b>${dayjs(data.timeStamp * 1000).format('YYYY/MM/DD HH:mm:ss')}</div>
              </div>
            `
          }

          return ''
        }
      },
      dataset: [
        {
          source: valuations || []
        }
      ],
      series: [
        {
          type: 'line',
          name: 'Perfect Model',
          data: linearData,
          symbol: 'false'
        },
        {
          type: 'scatter',
          name: 'Valuation',
          datasetIndex: 0,
          encode: {
            x: 'price',
            y: 'nftValuation'
          }
        },
      ],
      legend: {
        data: ['Perfect Model', 'Valuation']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [valuations])

  return (
    <Box sx={{ width: '1200px', height: '1030px' }}>
      <Typography variant={'h5'}>Moonbeam valuation deviation:</Typography>
      <div>MAPE: {mape ? `${mape * 100}%` : '-'}</div>
      <Box sx={{ display: 'flex', justifyContent: 'end' }} m={'32px 0'}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Box width={'256px'}>
            <FormControl fullWidth>
              <InputLabel id="Contract Address">Contract Address</InputLabel>
              <Select
                id={'Contract Address'}
                labelId={'Contract Address'}
                value={contractAddress}
                label="Contract Address"
                onChange={e => setContractAddress(e.target.value as any)}
              >
                {
                  whitelists && Object.values(whitelists).map((o: any, idx) => (
                    <MenuItem value={o.contractAddress} key={idx}>{o.nftName}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>

          <TextField id="alpha" label="Alpha" variant="outlined" onChange={e => setAlpha(Number(e.target.value))} />
          <Button onClick={handleSearch} disabled={isFetching}>
            { isFetching ? 'loading...' : 'Search' }
          </Button>
        </Box>
      </Box>
      <ReactECharts
        option={option}
        opts={{
          height: 800
        }}
      />
    </Box>
  )
}
