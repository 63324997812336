import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback
} from 'react'
import { useWhitelistQuery } from '@/hooks/queries'
import { nftTransactionRecord, transactionDetail, featNftRecord, nftDetail, collectionDetail, featNftCollectionRecord, aiNftValuationRecord, valuationDetail } from '@/hooks/queries/monitor'
import {
  Autocomplete,
  Box,
  Card,
  FormControl,
  TextField,
  Typography
} from '@mui/material'
import ReactECharts from 'echarts-for-react'
import { EChartsOption } from 'echarts'
import dayjs, { Dayjs } from 'dayjs'
import { CollectionContext } from '@/CollectionContext'


const NftTransactionRecord: React.FC = () => {
  const { collection } = useContext(CollectionContext)

  const { data: nftRecord } = nftTransactionRecord()
  const { data: data } = transactionDetail({ id: collection })

  const option: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'category',
        data: data?.map(p => dayjs(p.dataKey).format('YY/MM/DD')),
      },
      yAxis: {
        type: 'value'
      },
      tooltip: {
        trigger: 'axis',
      },
      series: [
        {
          type: 'line',
          name: 'avgPrice',
          data: data?.map(o => o.avgPrice),
          smooth: true
        },
        {
          type: 'line',
          name: 'nftCnt',
          data: data?.map(o => o.nftCnt),
          lineStyle: {
            opacity: .5
          },
          stack: 'confidence-band',
          symbol: 'none',
          smooth: true
        },
        {
          type: 'line',
          name: 'recordCnt',
          data: data?.map(o => o.recordCnt),
        },
      ],
      legend: {
        data: ['avgPrice', 'nftCnt', 'recordCnt']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [data])

  return (
    <Box sx={{ width: 1600, mb: '128px', height: '420px' }}>
      <Typography variant={'h5'}>Nft Transaction:</Typography>
      <Box m={'16px 0'} width={'100%'} display={'flex'} justifyContent={'center'}>
        <Box sx={{ width: '256px', gap: '22px' }} >
          <Typography variant={'h5'}>{dayjs(nftRecord?.dataKey).format('YY/MM/DD')}</Typography>
          <Typography variant={'h5'}>系列个数: {nftRecord?.whiteListCnt}</Typography>
        </Box>
        <Box sx={{ width: '256px', gap: '22px' }} >
          <Typography variant={'h5'}>{dayjs(nftRecord?.dataKey).format('YY/MM/DD')}</Typography>
          <Typography variant={'h5'}>NFT个数: {nftRecord?.nftCnt}</Typography>
        </Box>
        <Box sx={{ width: '256px', gap: '22px' }} >
          <Typography variant={'h5'}>{dayjs(nftRecord?.dataKey).format('YY/MM/DD')}</Typography>
          <Typography variant={'h5'}>记录数: {nftRecord?.cnt}</Typography>
        </Box>
      </Box>
      <ReactECharts option={option} />
    </Box>
  )
}
const FeatNftRecord: React.FC = () => {
  const { collection } = useContext(CollectionContext)

  const { data: nftRecord } = featNftRecord()
  const { data: data } = nftDetail({ id: collection })

  const option: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'category',
        data: data?.map(p => dayjs(p.dataKey).format('YY/MM/DD')),
      },
      yAxis: {
        type: 'value'
      },
      tooltip: {
        trigger: 'axis',
      },
      series: [
        {
          type: 'line',
          name: 'nftCnt',
          data: data?.map(o => o.nftCnt)
        },
        {
          type: 'line',
          name: 'recordCnt',
          data: data?.map(o => o.recordCnt),
        },
      ],
      legend: {
        data: [ 'nftCnt', 'recordCnt']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [data])

  return (
    <Box sx={{ width: 1600, mb: '128px', height: '420px' }}>
      <Typography variant={'h5'}>Feat Nft:</Typography>
      <Box m={'16px 0'} width={'100%'} display={'flex'} justifyContent={'center'}>
        <Box sx={{ width: '256px', gap: '22px' }} >
          <Typography variant={'h5'}>{dayjs(nftRecord?.dataKey).format('YY/MM/DD')}</Typography>
          <Typography variant={'h5'}>系列个数: {nftRecord?.whiteListCnt}</Typography>
        </Box>
        <Box sx={{ width: '256px', gap: '22px' }} >
          <Typography variant={'h5'}>{dayjs(nftRecord?.dataKey).format('YY/MM/DD')}</Typography>
          <Typography variant={'h5'}>NFT个数: {nftRecord?.nftCnt}</Typography>
        </Box>
        <Box sx={{ width: '256px', gap: '22px' }} >
          <Typography variant={'h5'}>{dayjs(nftRecord?.dataKey).format('YY/MM/DD')}</Typography>
          <Typography variant={'h5'}>记录数: {nftRecord?.cnt}</Typography>
        </Box>
      </Box>
      <ReactECharts option={option} />
    </Box>
  )
}

const FeatNftCollectionRecord: React.FC = () => {
  const { collection } = useContext(CollectionContext)

  const { data: nftRecord } = featNftCollectionRecord()
  const { data: data } = collectionDetail({ id: collection })

  const option: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'category',
        data: data?.map(p => dayjs(p.dataKey).format('YY/MM/DD')),
      },
      yAxis: {
        type: 'value'
      },
      tooltip: {
        trigger: 'axis',
      },
      series: [
        {
          type: 'line',
          name: 'med1DayPrice',
          data: data?.map(o => o.med1DayPrice),
        },
        {
          type: 'line',
          name: 'med3DayPrice',
          data: data?.map(o => o.med3DayPrice),
        },
        {
          type: 'line',
          name: 'med7DayPrice',
          data: data?.map(o => o.med7DayPrice),
        },
        {
          type: 'line',
          name: 'med15DayPrice',
          data: data?.map(o => o.med15DayPrice),
        },
        {
          type: 'line',
          name: 'med30DayPrice',
          data: data?.map(o => o.med30DayPrice),
        },
        {
          type: 'line',
          name: 'med90DayPrice',
          data: data?.map(o => o.med90DayPrice),
        },
        {
          type: 'line',
          name: 'med180DayPrice',
          data: data?.map(o => o.med180DayPrice),
        },
        {
          type: 'line',
          name: 'avg3DayTrad',
          data: data?.map(o => o.avg3DayTrad),
        },
        {
          type: 'line',
          name: 'avg7DayTrad',
          data: data?.map(o => o.avg7DayTrad),
        },
        {
          type: 'line',
          name: 'avg15DayTrad',
          data: data?.map(o => o.avg15DayTrad),
        },
        {
          type: 'line',
          name: 'avg30DayTrad',
          data: data?.map(o => o.avg30DayTrad),
        },
        {
          type: 'line',
          name: 'avg90DayTrad',
          data: data?.map(o => o.avg90DayTrad),
        },
        {
          type: 'line',
          name: 'avg180DayTrad',
          data: data?.map(o => o.avg180DayTrad),
        },
        {
          type: 'line',
          name: 'avgHoldings',
          data: data?.map(o => o.avgHoldings),
        },
        {
          type: 'line',
          name: 'buyerCnt',
          data: data?.map(o => o.buyerCnt),
        },
        {
          type: 'line',
          name: 'cnt',
          data: data?.map(o => o.cnt),
        },
        {
          type: 'line',
          name: 'turnoverRate',
          data: data?.map(o => o.turnoverRate),
        },
      ],
      legend: {
        data: [ 'med1DayPrice', 'med3DayPrice', 'med7DayPrice', 'med15DayPrice', 'med30DayPrice', 'med90DayPrice', 'med180DayPrice', 'avg3DayTrad', 'avg7DayTrad', 'avg15DayTrad' ,'avg30DayTrad', 'avg90DayTrad', 'avg180DayTrad', 'avgHoldings', 'buyerCnt', 'cnt', 'turnoverRate']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [data])

  return (
    <Box sx={{ width: 1600, mb: '128px', height: '420px' }}>
      <Typography variant={'h5'}>Feat Nft Collection:</Typography>
      <Box m={'16px 0'} width={'100%'} display={'flex'} justifyContent={'center'}>
        <Box sx={{ width: '256px', gap: '22px' }} >
          <Typography variant={'h5'}>{dayjs(nftRecord?.dataKey).format('YY/MM/DD')}</Typography>
          <Typography variant={'h5'}>系列个数: {nftRecord?.whiteListCnt}</Typography>
        </Box>
        <Box sx={{ width: '256px', gap: '22px' }} >
          <Typography variant={'h5'}>{dayjs(nftRecord?.dataKey).format('YY/MM/DD')}</Typography>
          <Typography variant={'h5'}>记录数: {nftRecord?.cnt}</Typography>
        </Box>
      </Box>
      <ReactECharts option={option} />
    </Box>
  )
}

const ValuationRecord: React.FC = () => {
  const { collection } = useContext(CollectionContext)

  const { data: nftRecord } = aiNftValuationRecord()
  const { data: data } = valuationDetail({ id: collection })

  const option: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'category',
        data: data?.map(p => dayjs(p.dataKey).format('YY/MM/DD')),
      },
      yAxis: {
        type: 'value'
      },
      tooltip: {
        trigger: 'axis',
      },
      series: [
        {
          type: 'line',
          name: 'nftCnt',
          data: data?.map(o => o.nftCnt)
        },
        {
          type: 'line',
          name: 'cnt',
          data: data?.map(o => o.cnt),
        },
        {
          type: 'line',
          name: 'avgAiValuation',
          data: data?.map(o => o.avgAiValuation),
        },
        {
          type: 'line',
          name: 'avgSimpleValuation',
          data: data?.map(o => o.avgSimpleValuation),
        },
      ],
      legend: {
        data: [ 'nftCnt', 'cnt', 'avgAiValuation', 'avgSimpleValuation']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [data])

  return (
    <Box sx={{ width: 1600, mb: '128px', height: '420px' }}>
      <Typography variant={'h5'}>Ai Nft Valuation:</Typography>
      <Box m={'16px 0'} width={'100%'} display={'flex'} justifyContent={'center'}>
        <Box sx={{ width: '256px', gap: '22px' }} >
          <Typography variant={'h5'}>{dayjs(nftRecord?.dataKey).format('YY/MM/DD')}</Typography>
          <Typography variant={'h5'}>系列个数: {nftRecord?.whiteListCnt}</Typography>
        </Box>
        <Box sx={{ width: '256px', gap: '22px' }} >
          <Typography variant={'h5'}>{dayjs(nftRecord?.dataKey).format('YY/MM/DD')}</Typography>
          <Typography variant={'h5'}>NFT个数: {nftRecord?.nftCnt}</Typography>
        </Box>
        <Box sx={{ width: '256px', gap: '22px' }} >
          <Typography variant={'h5'}>{dayjs(nftRecord?.dataKey).format('YY/MM/DD')}</Typography>
          <Typography variant={'h5'}>记录数: {nftRecord?.cnt}</Typography>
        </Box>
      </Box>
      <ReactECharts option={option} />
    </Box>
  )
}
export const Monitor = () => {
  const { collection, setCollection } = useContext(CollectionContext)
  const { setSymbol } = useContext(CollectionContext)

  const { data: whitelist } = useWhitelistQuery()
  

  const options = useMemo(
    () => whitelist?.map(o => ({ ...o, label: o.nftName })) || [],
    [whitelist]
  )

  return (
    <section>
      <Box sx={{ position: 'fixed', left: '30px', top: '30%', width: '320px' }}>
        <FormControl fullWidth>
          <Autocomplete
            value={options?.find(o => o.id === collection) || null}
            disablePortal
            id={'Slug-symbol'}
            options={options}
            getOptionLabel={option =>
              `${option.nftName}(${option.symbol})-${option.priceType}`}
            renderInput={params => (
              <TextField {...params} label="Collection" />
            )}
            onChange={(_e, value) => {
              setCollection(value?.id || '')
              setSymbol(value?.symbol || '')
            }}
          />
        </FormControl>
      </Box>

      <Card sx={{ padding: '16px', marginBottom: '48px' }} >
        <NftTransactionRecord />
        <FeatNftRecord />
        <FeatNftCollectionRecord />
        <ValuationRecord />
      </Card>
    </section>
  )
}
