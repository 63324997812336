import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react'
import {
  useAllValuationDeviationsQuery,
  useAllValuationsQuery,
  useValuationDeviationBasedOnTransactionQuery,
  useValuationDeviationByDailyTrendQuery,
  useWhitelistQuery,
  ValuationDeviationBasedOnTransaction,
  ValuationV2DeviationBasedOnTransaction,
  ValuationDeviationByDailyTrend,
  ValuationDeviationMetric,
  valuationDeviationMetrics,
  Whitelist,
  useAllSalePricesQuery,
  SimpleValuationDeviationBasedOnTransactionQuery,
  AiValuationDeviationQuery,
  SimpleValuationDeviationQuery,
  SimpleValuationDeviationV2BasedOnTransactionQuery,
  SimpleValuationV2DeviationQuery,
  SimpleValuationV3DeviationQuery,
  SimpleValuationDeviationV3BasedOnTransactionQuery,
  AiValuationDeviationBasedOnTransactionQuery,
  AiValuationDeviationBasedOnTransactionQuery2
} from '@/hooks/queries'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import ReactECharts from 'echarts-for-react'
import { EChartsOption } from 'echarts'
import dayjs, { Dayjs } from 'dayjs'
import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CollectionContext } from '@/CollectionContext'

const isNumber = (val: any) => val && typeof val === 'number'

const sumOf = (data: number[]) => data.reduce((sum, next) => sum + next, 0)

const medianOf = (data: number[]) => {
  const copy = [...data]
  copy.sort()
  return copy[Math.floor(data.length / 2)]
}

const avgOf = (data: number[]) => data.length === 0 ? undefined : sumOf(data) / data.length

const calcVariance = (data: number[]) => {
  const avg = sumOf(data) / data.length

  return sumOf(data.map(item => (item - avg) * (item - avg))) / data.length
}

const standardDeviationOf = (data: number[]) => {
  return Math.sqrt(calcVariance(data))
}

const isOutlier = (item: number, avg: number | undefined, median: number, standardDeviation: number) => {
  if (avg === undefined) return false

  return Math.abs(item - avg) > 3 * standardDeviation || Math.abs(item - median) > 20 * Math.abs(median)
}

const stepLen = (step: number, distributions: number[]) => {
  if (!distributions) return {}
  const min = Math.min(...distributions)
  const max = Math.max(...distributions)
  const stepLen = (max - min) / step
  const getLevel = (item: number) => {
    for (let i = 0; i * stepLen + min <= max; i++) {
      if (min + stepLen * i <= item && item <= min + stepLen * (i + 1)) {
        return ((min + stepLen * i) * 100).toFixed(4)
      }
    }
  }
  return distributions.reduce<Record<string, number>>((res, next) => {
    const level = getLevel(next) + '%'
    if (!level) {
      return res
    }

    const cnt = res[level] || 0

    return {
      ...res,
      [level]: cnt + 1
    }
  }, {})
}

const ValuationDeviationByDailyTrendChart: React.FC = () => {
  const { collection } = useContext(CollectionContext)
  const [endTime] = useState(Date.now())
  const [filterOutlier, setFilterOutlier] = useState(false)
  const [metric, setMetric] = useState<ValuationDeviationMetric>(valuationDeviationMetrics.mape)

  const { data: byDailyTrend } = useValuationDeviationByDailyTrendQuery({
    id: collection,
    metric,
    startTime: 0,
    endTime
  })

  const data = useMemo(() => {
    const minData = byDailyTrend?.map(o => o.min) || []
    const maxData = byDailyTrend?.map(o => o.max) || []
    const avgData = byDailyTrend?.map(o => o.avg) || []

    const avgOfMinData = avgOf(minData)
    const avgOfMaxData = avgOf(maxData)
    const avgOfAvgData = avgOf(avgData)

    const medianOfMinData = medianOf(minData)
    const medianOfMaxData = medianOf(maxData)
    const medianOfAvgData = medianOf(avgData)

    const standardDeviationOfMinData = standardDeviationOf(minData)
    const standardDeviationOfMaxData = standardDeviationOf(maxData)
    const standardDeviationOfAvgData = standardDeviationOf(avgData)

    if (!filterOutlier) return byDailyTrend || []

    return byDailyTrend?.filter(({
      avg,
      max,
      min
    }) =>
      isNumber(min)
        && isNumber(max)
        && isNumber(avg)
        && !isOutlier(min, avgOfMinData, medianOfMinData, standardDeviationOfMinData)
        && !isOutlier(max, avgOfMaxData, medianOfMaxData, standardDeviationOfMaxData)
        && !isOutlier(avg, avgOfAvgData, medianOfAvgData, standardDeviationOfAvgData)
    ) || []
  }, [byDailyTrend, filterOutlier])

  const base = useMemo(() => -data.map(o => o.min).reduce((min, next) => Math.min(min, next), Infinity), [data])

  const option: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'category',
        data: data.map(o => dayjs(o.transDay).format('YY/MM/DD')),
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (val: number) => `${((val - base) * 100).toFixed(2)}%`
        },
      },
      tooltip: {
        trigger: 'axis',
      },
      series: [
        {
          type: 'line',
          name: 'Avg',
          data: data.map(o => o.avg + base),
          smooth: true,
          tooltip: {
            valueFormatter: (value: any) => `${((value - base) * 100).toFixed(2)}%`
          }
        },
        {
          type: 'line',
          name: 'Min',
          data: data?.map(item => item.min + base),
          lineStyle: {
            opacity: .5
          },
          stack: 'confidence-band',
          symbol: 'none',
          smooth: true,
          tooltip: {
            valueFormatter: (value: any) => `${((value - base) * 100).toFixed(2)}%`
          }
        },
        {
          type: 'line',
          name: 'Max',
          data: data?.map(item => item.max - item.min),
          lineStyle: {
            opacity: .5
          },
          areaStyle: {
            color: '#dddddd'
          },
          stack: 'confidence-band',
          symbol: 'none',
          smooth: true,
          tooltip: {
            valueFormatter: (value: any) => `${((value - base) * 100).toFixed(2)}%`
          }
        },
      ],
      legend: {
        data: ['Avg', 'Min', 'Max']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [data, base])

  return (
    <Box sx={{ width: 1600, mb: '128px', height: '420px' }}>
      <Typography variant={'h5'}>Valuation Deviation By Daily Trend:</Typography>
      <Box m={'16px 0'} width={'100%'} display={'flex'} justifyContent={'end'}>
        <Box sx={{ width: '256px', gap: '16px' }} display={'flex'}>
          <FormControl>
            <InputLabel id="Metric">Metric</InputLabel>
            <Select
              id={'Metric'}
              labelId={'Metric'}
              value={metric}
              label="Metric"
              onChange={e => setMetric(e.target.value as any)}
            >
              {
                Object.values(valuationDeviationMetrics)?.map(o => (
                  <MenuItem value={o} key={o}>{o}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <Tooltip
            title={'Filter out data which: 1) greater/less than 3 * standard deviations; 2) greater than 20 * median'}
          >
            <FormControlLabel
              control={<Checkbox checked={filterOutlier} onChange={e => setFilterOutlier(e.target.checked)} />}
              label="Filter Outliers"
            />
          </Tooltip>
        </Box>
      </Box>
      <ReactECharts option={option} />
    </Box>
  )
}

const MiniSizeValuationDeviationByDailyTrendChart: React.FC<{ deviations: Array<ValuationDeviationByDailyTrend> }> = ({
  deviations: data
}) => {
  const base = useMemo(() => -data.map(o => o.min).reduce((min, next) => Math.min(min, next), Infinity), [data])

  const option: EChartsOption = useMemo(() => {
    return ({
      grid: {
        top: 0,
        bottom: 0
      },
      xAxis: {
        type: 'category',
        data: data.map(o => dayjs(o.transDay).format('YY/MM/DD')),
        show: false,
      },
      yAxis: [
        {
          type: 'value',
          show: false,
          axisLabel: {
            formatter: (val: number) => `${((val - base) * 100).toFixed(2)}%`
          },
        },
        {
          type: 'value',
          show: false,
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
      series: [
        {
          type: 'line',
          name: 'Avg',
          data: data.map(o => o.avg + base),
          smooth: true,
          symbol: 'none',
          yAxisIndex: 0,
          tooltip: {
            valueFormatter: (value: any) => `${((value - base) * 100).toFixed(2)}%`
          }
        },
        {
          type: 'line',
          name: 'Min',
          data: data?.map(item => item.min + base),
          lineStyle: {
            opacity: .5
          },
          stack: 'confidence-band',
          symbol: 'none',
          smooth: true,
          yAxisIndex: 0,
          tooltip: {
            valueFormatter: (value: any) => `${((value - base) * 100).toFixed(2)}%`
          }
        },
        {
          type: 'line',
          name: 'Max',
          data: data?.map(item => item.max - item.min),
          lineStyle: {
            opacity: .5
          },
          areaStyle: {
            color: '#dddddd'
          },
          stack: 'confidence-band',
          symbol: 'none',
          smooth: true,
          yAxisIndex: 0,
          tooltip: {
            valueFormatter: (value: any) => `${((value - base) * 100).toFixed(2)}%`
          }
        },
        {
          type: 'line',
          name: 'Sales',
          data: data?.map(item => item.sales || 0) || [],
          lineStyle: {
            opacity: .5,
            color: '#613194'
          },
          symbol: 'none',
          smooth: true,
          yAxisIndex: 1
        },
      ],
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
      ],
    })
  }, [data, base])

  return (
    <ReactECharts option={option} opts={{ height: 80, width: 160 }} style={{ height: 80, width: 160 }} />
  )
}

const SimpleValuationDeviationBasedOnTransactionChart: React.FC = () => {
  const { collection } = useContext(CollectionContext)
  const DATE_RANGE_QUICK_OPTIONS = [1, 3, 5, 7, 15, 30, 90]

  const [activeDateRangeQuickOption, setActiveDateRangeQuickOption] = useState<number | null>(null)
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null])
  const [alpha, setAlpha] = useState<number>(0)

  // useEffect(() => {
  //   if (activeDateRangeQuickOption) {
  //     const now = dayjs()

  //     setDateRange([
  //       now.subtract(activeDateRangeQuickOption, 'day'),
  //       now
  //     ])
  //   } else {
  //     setDateRange([null, null])
  //   }
  //   setAlpha(alpha)
  // }, [activeDateRangeQuickOption, alpha])
  const handleSearch = useCallback(() => {
    if (activeDateRangeQuickOption) {
      const now = dayjs()
      setDateRange([
        now.subtract(activeDateRangeQuickOption, 'day'),
        now
      ])
    }
    setAlpha(alpha)
  }, [alpha, activeDateRangeQuickOption])
  

  const query = useMemo(() => {
    const [start, end] = dateRange
    return {
      id: collection,
      alpha: alpha,
      startTime: start?.toDate().getTime() || 0,
      endTime: end?.toDate().getTime() || Date.now()
    }
  }, [dateRange, collection])
  // const { data: byTransactionV2 } = SimpleValuationDeviationV2BasedOnTransactionQuery(query)
  const { data: byTransactionV3 } = SimpleValuationDeviationV3BasedOnTransactionQuery(query)

  // const { data: byTransaction, isFetching } = SimpleValuationDeviationBasedOnTransactionQuery(query)
  const { data: byTransaction, isFetching } = AiValuationDeviationBasedOnTransactionQuery(query)

  const { data: byTransaction2 } = AiValuationDeviationBasedOnTransactionQuery2(query)

  const mapes = React.useMemo(() => byTransaction?.map(o => Math.abs((o.aiValuation - o.totalAmount) / o.totalAmount)), [byTransaction])
  mapes?.sort((a, b) => a-b)

  const mapes2 = React.useMemo(() => byTransaction2?.map(o => Math.abs((o.aiValuation - o.totalAmount) / o.totalAmount)), [byTransaction2])
  mapes2?.sort((a, b) => a-b)

  const mape70 = React.useMemo(() => {
    return mapes ? mapes.slice(0, mapes.length * 0.7).reduce((r, cur) => (r + cur), 0) / mapes.length * 0.7 : undefined
  }, [mapes])
  const mape80 = React.useMemo(() => {
    return mapes ? mapes.slice(0, mapes.length * 0.8).reduce((r, cur) => (r + cur), 0) / mapes.length * 0.8 : undefined
  }, [mapes])
  const mape90 = React.useMemo(() => {
    return mapes ? mapes.slice(0, mapes.length * 0.9).reduce((r, cur) => (r + cur), 0) / mapes.length * 0.9 : undefined
  }, [mapes])

  const mape702 = React.useMemo(() => {
    return mapes2 ? mapes2.slice(0, mapes2.length * 0.7).reduce((r, cur) => (r + cur), 0) / mapes2.length * 0.7 : undefined
  }, [mapes2])
  const mape802 = React.useMemo(() => {
    return mapes2 ? mapes2.slice(0, mapes2.length * 0.8).reduce((r, cur) => (r + cur), 0) / mapes2.length * 0.8 : undefined
  }, [mapes2])
  const mape902 = React.useMemo(() => {
    return mapes2 ? mapes2.slice(0, mapes2.length * 0.9).reduce((r, cur) => (r + cur), 0) / mapes2.length * 0.9 : undefined
  }, [mapes2])

  const mape = React.useMemo(() => {
    return byTransaction ? byTransaction.reduce((r, cur) => (r + Math.abs((cur.aiValuation - cur.totalAmount) / cur.totalAmount)), 0) / byTransaction.length : undefined
  }, [byTransaction])

  const mape2 = React.useMemo(() => {
    return byTransaction2 ? byTransaction2.reduce((r, cur) => (r + Math.abs((cur.aiValuation - cur.totalAmount) / cur.totalAmount)), 0) / byTransaction2.length : undefined
  }, [byTransaction2])

  // const mapeV2 = React.useMemo(() => {
  //   return byTransactionV2 ? byTransactionV2.reduce((r, cur) => (r + Math.abs((cur.valuationV2 - cur.salePrice) / cur.salePrice)), 0) / byTransactionV2.length : undefined
  // }, [byTransactionV2])

  const mapeV3 = React.useMemo(() => {
    return byTransactionV3 ? byTransactionV3.reduce((r, cur) => (r + Math.abs((cur.valuation - cur.salePrice) / cur.salePrice)), 0) / byTransactionV3.length : undefined
  }, [byTransactionV3])


  const maxSalePrice = useMemo(() => byTransaction?.map(o => o.totalAmount).reduce((max, next) => Math.max(max, next), 0) || 0, [byTransaction])
  
  const linearData = useMemo(() => new Array(100).fill(undefined).map((_, i) => ([(i / 100 * maxSalePrice).toFixed(3), (i / 100 * maxSalePrice).toFixed(2)])), [maxSalePrice])


  const maxSalePrice2 = useMemo(() => byTransaction2?.map(o => o.totalAmount).reduce((max, next) => Math.max(max, next), 0) || 0, [byTransaction2])
  
  const linearData2 = useMemo(() => new Array(100).fill(undefined).map((_, i) => ([(i / 100 * maxSalePrice2).toFixed(3), (i / 100 * maxSalePrice2).toFixed(2)])), [maxSalePrice2])
  // const maxSalePriceV2 = useMemo(() => byTransactionV2?.map(o => o.salePrice).reduce((max, next) => Math.max(max, next), 0) || 0, [byTransactionV2])

  // const linearDataV2 = useMemo(() => new Array(100).fill(undefined).map((_, i) => ([(i / 100 * maxSalePriceV2).toFixed(3), (i / 100 * maxSalePriceV2).toFixed(2)])), [maxSalePriceV2])

  const mapeV3s = React.useMemo(() => byTransactionV3?.map(o => Math.abs((o.valuation - o.salePrice) / o.salePrice)), [byTransactionV3])
  mapeV3s?.sort((a, b) => a-b)
  const mapeV370 = React.useMemo(() => {
    return mapeV3s ? mapeV3s.slice(0, mapeV3s.length * 0.7).reduce((r, cur) => (r + cur), 0) / mapeV3s.length * 0.7 : undefined
  }, [mapeV3s])
  const mapeV380 = React.useMemo(() => {
    return mapeV3s ? mapeV3s.slice(0, mapeV3s.length * 0.8).reduce((r, cur) => (r + cur), 0) / mapeV3s.length * 0.8 : undefined
  }, [mapeV3s])
  const mapeV390 = React.useMemo(() => {
    return mapeV3s ? mapeV3s.slice(0, mapeV3s.length * 0.9).reduce((r, cur) => (r + cur), 0) / mapeV3s.length * 0.9 : undefined
  }, [mapeV3s])

  const maxSalePriceV3 = useMemo(() => byTransactionV3?.map(o => o.salePrice).reduce((max, next) => Math.max(max, next), 0) || 0, [byTransactionV3])

  const linearDataV3 = useMemo(() => new Array(100).fill(undefined).map((_, i) => ([(i / 100 * maxSalePriceV3).toFixed(3), (i / 100 * maxSalePriceV3).toFixed(2)])), [maxSalePriceV3])

  const option: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'value',
        name: 'totalAmount'
      },
      yAxis: {
        type: 'value',
        name: 'aiValuation'
      },
      tooltip: {
        trigger: 'item',
        hideDelay: 250,
        enterable: true,
        position: point => point,
        formatter: (props: any) => {
          if (props.componentSubType === 'scatter') {
            const data: any = props.data
            return `
              <div style="display: flex; flex-direction: column; align-items: start;">
                <div><b>NFT Name: </b>${data.nftName}</div>
                <br>

                <div><b>whiteListId: </b>${data.whiteListId}</div>
                <div><b>totalAmount: </b>${data.totalAmount}</div>
                <div><b>simleValuation: </b>${data.simpleValuation}</div>
                <div><b>aiValuation: </b>${data.aiValuation}</div>
                <div>
                  <b>Deviation: </b>
                  <span style="color: ${data.aiValuation < data.totalAmount ? 'green' : 'red'}">${((data.aiValuation - data.totalAmount) / data.totalAmount * 100).toFixed(2)}%</span>
                </div>
                <div><b>Transaction Time: </b>${dayjs(data.timestamp * 1000).format('YYYY/MM/DD HH:mm:ss')}</div>
                <a href="https://solscan.io/tx/${data.transactionSignature}" target="_blank" rel="noreferrer">View transaction on Solscan</a>
              </div>
            `
          }

          return ''
        }
      },
      dataset: [
        {
          source: byTransaction || []
        }
      ],
      series: [
        {
          type: 'line',
          name: 'Perfect Model',
          data: linearData,
          symbol: 'false'
        },
        {
          type: 'scatter',
          name: 'Valuation',
          datasetIndex: 0,
          encode: {
            x: 'totalAmount',
            y: 'aiValuation'
          }
        },
      ],
      legend: {
        data: ['Perfect Model', 'Valuation']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [byTransaction])

  const option2: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'value',
        name: 'totalAmount'
      },
      yAxis: {
        type: 'value',
        name: 'aiValuation'
      },
      tooltip: {
        trigger: 'item',
        hideDelay: 250,
        enterable: true,
        position: point => point,
        formatter: (props: any) => {
          if (props.componentSubType === 'scatter') {
            const data: any = props.data
            return `
              <div style="display: flex; flex-direction: column; align-items: start;">
                <div><b>NFT Name: </b>${data.nftName}</div>
                <br>

                <div><b>whiteListId: </b>${data.whiteListId}</div>
                <div><b>totalAmount: </b>${data.totalAmount}</div>
                <div><b>simleValuation: </b>${data.simpleValuation}</div>
                <div><b>aiValuation: </b>${data.aiValuation}</div>
                <div>
                  <b>Deviation: </b>
                  <span style="color: ${data.aiValuation < data.totalAmount ? 'green' : 'red'}">${((data.aiValuation - data.totalAmount) / data.totalAmount * 100).toFixed(2)}%</span>
                </div>
                <div><b>Transaction Time: </b>${dayjs(data.timestamp * 1000).format('YYYY/MM/DD HH:mm:ss')}</div>
                <a href="https://solscan.io/tx/${data.transactionSignature}" target="_blank" rel="noreferrer">View transaction on Solscan</a>
              </div>
            `
          }

          return ''
        }
      },
      dataset: [
        {
          source: byTransaction2 || []
        }
      ],
      series: [
        {
          type: 'line',
          name: 'Perfect Model',
          data: linearData2,
          symbol: 'false'
        },
        {
          type: 'scatter',
          name: 'Valuation',
          datasetIndex: 0,
          encode: {
            x: 'totalAmount',
            y: 'aiValuation'
          }
        },
      ],
      legend: {
        data: ['Perfect Model', 'Valuation']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [byTransaction2])
  // const optionV2: EChartsOption = useMemo(() => {
  //   return ({
  //     xAxis: {
  //       type: 'value',
  //       name: 'Sale Price'
  //     },
  //     yAxis: {
  //       type: 'value',
  //       name: 'ValuationV2'
  //     },
  //     tooltip: {
  //       trigger: 'item',
  //       hideDelay: 250,
  //       enterable: true,
  //       position: point => point,
  //       formatter: (props: any) => {
  //         if (props.componentSubType === 'scatter') {
  //           const data: ValuationV2DeviationBasedOnTransaction = props.data
  //           return `
  //             <div style="display: flex; flex-direction: column; align-items: start; width: 500px">
  //               <div><b>NFT Name: </b>${data.name}</div>
  //               <br>
  //               ${data.history.map(o => `<div><b>${dayjs(o.transactionTime * 1000).format('YYYY/MM/DD HH:mm:ss')}：</b>${o.valuationDeviation.toFixed(3)}</div>`).join('')}

  //               <div><b>symbol: </b>${data.symbol}</div>
  //               <div><b>Sale Price: </b>${data.salePrice}</div>
  //               <div><b>ValuationV1: </b>${data.valuationV1}</div>
  //               <div><b>ValuationV2: </b>${data.valuationV2}</div>
  //               <div><b>bias: </b>${data.bias}</div>
  //               <div><b>strategyBias: </b>${data.strategyBias}</div>
  //               <div><b>rarity: </b>${data.rarity}</div>
  //               <div><b>rarityMedian: </b>${data.rarityMedian}</div>
  //               <div><b>collectionValuation: </b>${data.collectionValuation}</div>
  //               <div>
  //                 <b>Deviation: </b>
  //                 <span style="color: ${data.valuation < data.salePrice ? 'green' : 'red'}">${((data.valuationV2 - data.salePrice) / data.salePrice * 100).toFixed(2)}%</span>
  //               </div>
  //               <div><b>Transaction Program: </b>${data.program}</div>
  //               <div><b>Transaction Time: </b>${dayjs(data.transactionTime * 1000).format('YYYY/MM/DD HH:mm:ss')}</div>
  //               <a href="https://solscan.io/tx/${data.transactionSignature}" target="_blank" rel="noreferrer">View transaction on Solscan</a>
  //             </div>
  //           `
  //         }

  //         return ''
  //       }
  //     },
  //     dataset: [
  //       {
  //         source: byTransactionV2 || []
  //       }
  //     ],
  //     series: [
  //       {
  //         type: 'line',
  //         name: 'Perfect Model',
  //         data: linearDataV2,
  //         symbol: 'false'
  //       },
  //       {
  //         type: 'scatter',
  //         name: 'Valuation',
  //         datasetIndex: 0,
  //         itemStyle: {
  //           color(params) {
  //             const data = JSON.parse(JSON.stringify(params.data))
  //             if (Math.abs(data.valuationV2 - data.salePrice) > Math.abs(data.valuationV1 - data.salePrice)) {
  //               return 'red'
  //             }
  //             if (Math.abs(data.valuationV2 - data.salePrice) === Math.abs(data.valuationV1 - data.salePrice)) {
  //               return 'blue'
  //             }
  //             return 'green'
  //           },
  //         },
  //         encode: {
  //           x: 'salePrice',
  //           y: 'valuationV2'
  //         }
  //       },
  //     ],
  //     legend: {
  //       data: ['Perfect Model', 'Valuation']
  //     },
  //     dataZoom: [
  //       {
  //         type: 'inside',
  //         start: 0,
  //         end: 100
  //       },
  //       {
  //         start: 0,
  //         end: 100
  //       },
  //       {
  //         yAxisIndex: 0,
  //         start: 0,
  //         end: 100
  //       }
  //     ],
  //   })
  // }, [byTransactionV2])

  const optionV3: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'value',
        name: 'Sale Price'
      },
      yAxis: {
        type: 'value',
        name: 'Valuation'
      },
      tooltip: {
        trigger: 'item',
        hideDelay: 250,
        enterable: true,
        position: point => point,
        formatter: (props: any) => {
          if (props.componentSubType === 'scatter') {
            const data: any = props.data
            return `
              <div style="display: flex; flex-direction: column; align-items: start;">
                <div><b>NFT Name: </b>${data.name}</div>
                <br>

                <div><b>symbol: </b>${data.symbol}</div>
                <div><b>Sale Price: </b>${data.salePrice}</div>
                <div><b>Valuation: </b>${data.valuation}</div>
                <div><b>rarity: </b>${data.rarity}</div>
                <div><b>rarityMedian: </b>${data.rarityMedian}</div>
                <div><b>collectionValuation: </b>${data.collectionValuation}</div>
                <div>
                  <b>Deviation: </b>
                  <span style="color: ${data.valuation < data.salePrice ? 'green' : 'red'}">${((data.valuation - data.salePrice) / data.salePrice * 100).toFixed(2)}%</span>
                </div>
                <div><b>Transaction Program: </b>${data.program}</div>
                <div><b>Transaction Time: </b>${dayjs(data.transactionTime * 1000).format('YYYY/MM/DD HH:mm:ss')}</div>
                <a href="https://solscan.io/tx/${data.transactionSignature}" target="_blank" rel="noreferrer">View transaction on Solscan</a>
              </div>
            `
          }

          return ''
        }
      },
      dataset: [
        {
          source: byTransactionV3 || []
        }
      ],
      series: [
        {
          type: 'line',
          name: 'Perfect Model',
          data: linearDataV3,
          symbol: 'false'
        },
        {
          type: 'scatter',
          name: 'Valuation',
          datasetIndex: 0,
          encode: {
            x: 'salePrice',
            y: 'valuation'
          }
        },
      ],
      legend: {
        data: ['Perfect Model', 'Valuation']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [byTransactionV3])

  // const mapeStrategyV1 = React.useMemo(() => {
  //   return byTransaction ? byTransaction.reduce((r, cur) => (r + Math.abs((cur.strategyValue1 - cur.totalAmount) / cur.totalAmount)), 0) / byTransaction.length : undefined
  // }, [byTransaction])
  // const mapesStrategyV1 = React.useMemo(() => byTransaction?.map(o => Math.abs((o.strategyValue1 - o.totalAmount) / o.totalAmount)), [byTransaction])
  // mapesStrategyV1?.sort((a, b) => a-b)
  // const mape70StrategyV1 = React.useMemo(() => {
  //   return mapesStrategyV1 ? mapesStrategyV1.slice(0, mapesStrategyV1.length * 0.7).reduce((r, cur) => (r + cur), 0) / mapesStrategyV1.length * 0.7 : undefined
  // }, [mapesStrategyV1])
  // const mape80StrategyV1 = React.useMemo(() => {
  //   return mapesStrategyV1 ? mapesStrategyV1.slice(0, mapesStrategyV1.length * 0.8).reduce((r, cur) => (r + cur), 0) / mapesStrategyV1.length * 0.8 : undefined
  // }, [mapesStrategyV1])
  // const mape90StrategyV1 = React.useMemo(() => {
  //   return mapesStrategyV1 ? mapesStrategyV1.slice(0, mapesStrategyV1.length * 0.9).reduce((r, cur) => (r + cur), 0) / mapesStrategyV1.length * 0.9 : undefined
  // }, [mapesStrategyV1])

  // const optionStrategyV1: EChartsOption = useMemo(() => {
  //   return ({
  //     xAxis: {
  //       type: 'value',
  //       name: 'totalAmount'
  //     },
  //     yAxis: {
  //       type: 'value',
  //       name: 'aiValuation'
  //     },
  //     tooltip: {
  //       trigger: 'item',
  //       hideDelay: 250,
  //       enterable: true,
  //       position: point => point,
  //       formatter: (props: any) => {
  //         if (props.componentSubType === 'scatter') {
  //           const data: any = props.data
  //           return `
  //             <div style="display: flex; flex-direction: column; align-items: start;">
  //               <div><b>NFT Name: </b>${data.nftName}</div>
  //               <br>

  //               <div><b>whiteListId: </b>${data.whiteListId}</div>
  //               <div><b>totalAmount: </b>${data.totalAmount}</div>
  //               <div><b>simleValuation: </b>${data.simpleValuation}</div>
  //               <div><b>aiValuation: </b>${data.aiValuation}</div>
  //               <div>
  //                 <b>Deviation: </b>
  //                 <span style="color: ${data.aiValuation < data.totalAmount ? 'green' : 'red'}">${((data.aiValuation - data.totalAmount) / data.totalAmount * 100).toFixed(2)}%</span>
  //               </div>
  //               <div><b>Transaction Time: </b>${dayjs(data.timestamp * 1000).format('YYYY/MM/DD HH:mm:ss')}</div>
  //               <a href="https://solscan.io/tx/${data.transactionSignature}" target="_blank" rel="noreferrer">View transaction on Solscan</a>
  //             </div>
  //           `
  //         }

  //         return ''
  //       }
  //     },
  //     dataset: [
  //       {
  //         source: byTransaction || []
  //       }
  //     ],
  //     series: [
  //       {
  //         type: 'line',
  //         name: 'Perfect Model',
  //         data: linearData,
  //         symbol: 'false'
  //       },
  //       {
  //         type: 'scatter',
  //         name: 'Valuation',
  //         datasetIndex: 0,
  //         encode: {
  //           x: 'totalAmount',
  //           y: 'strategyValue1'
  //         }
  //       },
  //     ],
  //     legend: {
  //       data: ['Perfect Model', 'Valuation']
  //     },
  //     dataZoom: [
  //       {
  //         type: 'inside',
  //         start: 0,
  //         end: 100
  //       },
  //       {
  //         start: 0,
  //         end: 100
  //       },
  //       {
  //         yAxisIndex: 0,
  //         start: 0,
  //         end: 100
  //       }
  //     ],
  //   })
  // }, [byTransaction])


  // const mapeStrategyV2 = React.useMemo(() => {
  //   return byTransaction ? byTransaction.reduce((r, cur) => (r + Math.abs((cur.strategyValue2 - cur.totalAmount) / cur.totalAmount)), 0) / byTransaction.length : undefined
  // }, [byTransaction])
  // const mapesStrategyV2 = React.useMemo(() => byTransaction?.map(o => Math.abs((o.strategyValue2 - o.totalAmount) / o.totalAmount)), [byTransaction])
  // mapesStrategyV2?.sort((a, b) => a-b)
  // const mape70StrategyV2 = React.useMemo(() => {
  //   return mapesStrategyV2 ? mapesStrategyV2.slice(0, mapesStrategyV2.length * 0.7).reduce((r, cur) => (r + cur), 0) / mapesStrategyV2.length * 0.7 : undefined
  // }, [mapesStrategyV2])
  // const mape80StrategyV2 = React.useMemo(() => {
  //   return mapesStrategyV2 ? mapesStrategyV2.slice(0, mapesStrategyV2.length * 0.8).reduce((r, cur) => (r + cur), 0) / mapesStrategyV2.length * 0.8 : undefined
  // }, [mapesStrategyV2])
  // const mape90StrategyV2 = React.useMemo(() => {
  //   return mapesStrategyV2 ? mapesStrategyV2.slice(0, mapesStrategyV2.length * 0.9).reduce((r, cur) => (r + cur), 0) / mapesStrategyV2.length * 0.9 : undefined
  // }, [mapesStrategyV2])
  // const optionStrategyV2: EChartsOption = useMemo(() => {
  //   return ({
  //     xAxis: {
  //       type: 'value',
  //       name: 'totalAmount'
  //     },
  //     yAxis: {
  //       type: 'value',
  //       name: 'aiValuation'
  //     },
  //     tooltip: {
  //       trigger: 'item',
  //       hideDelay: 250,
  //       enterable: true,
  //       position: point => point,
  //       formatter: (props: any) => {
  //         if (props.componentSubType === 'scatter') {
  //           const data: any = props.data
  //           return `
  //             <div style="display: flex; flex-direction: column; align-items: start;">
  //               <div><b>NFT Name: </b>${data.nftName}</div>
  //               <br>

  //               <div><b>whiteListId: </b>${data.whiteListId}</div>
  //               <div><b>totalAmount: </b>${data.totalAmount}</div>
  //               <div><b>simleValuation: </b>${data.simpleValuation}</div>
  //               <div><b>aiValuation: </b>${data.aiValuation}</div>
  //               <div>
  //                 <b>Deviation: </b>
  //                 <span style="color: ${data.aiValuation < data.totalAmount ? 'green' : 'red'}">${((data.aiValuation - data.totalAmount) / data.totalAmount * 100).toFixed(2)}%</span>
  //               </div>
  //               <div><b>Transaction Time: </b>${dayjs(data.timestamp * 1000).format('YYYY/MM/DD HH:mm:ss')}</div>
  //               <a href="https://solscan.io/tx/${data.transactionSignature}" target="_blank" rel="noreferrer">View transaction on Solscan</a>
  //             </div>
  //           `
  //         }

  //         return ''
  //       }
  //     },
  //     dataset: [
  //       {
  //         source: byTransaction || []
  //       }
  //     ],
  //     series: [
  //       {
  //         type: 'line',
  //         name: 'Perfect Model',
  //         data: linearData,
  //         symbol: 'false'
  //       },
  //       {
  //         type: 'scatter',
  //         name: 'Valuation',
  //         datasetIndex: 0,
  //         encode: {
  //           x: 'totalAmount',
  //           y: 'strategyValue2'
  //         }
  //       },
  //     ],
  //     legend: {
  //       data: ['Perfect Model', 'Valuation']
  //     },
  //     dataZoom: [
  //       {
  //         type: 'inside',
  //         start: 0,
  //         end: 100
  //       },
  //       {
  //         start: 0,
  //         end: 100
  //       },
  //       {
  //         yAxisIndex: 0,
  //         start: 0,
  //         end: 100
  //       }
  //     ],
  //   })
  // }, [byTransaction])


  return (
    <Box sx={{ width: 1600, height: 1900 }}>
      <Box sx={{ display: 'flex', justifyContent: 'end' }} m={'32px 0'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                startText="From"
                endText="To"
                value={dateRange}
                onChange={newValue => {
                  setDateRange(newValue)
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
            <TextField id="alpha" label="Alpha" variant="outlined" onChange={e => setAlpha(Number(e.target.value))} />
            <Button onClick={handleSearch} disabled={isFetching}>
              { isFetching ? 'loading...' : 'Search' }
            </Button>
          </Box>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="date-range"
              name="date-range"
              value={activeDateRangeQuickOption}
              onChange={e => setActiveDateRangeQuickOption(+e.target.value)}
            >
              {
                DATE_RANGE_QUICK_OPTIONS.map(option => (
                  <FormControlLabel value={option} control={<Radio />} label={`In ${option} days`} key={option} />
                ))
              }
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }} >
        <Box sx={{ width: 800, height: 900 }}>
          <Typography variant={'h5'}>AI Valuation Deviation By Transactions:</Typography>
          <Typography variant={'h5'}>{collection}</Typography>
          <div>MAPE70%: {mape70 ? `${(mape70 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp;MAPE80%: {mape80 ? `${(mape80 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp; MAPE90%: {mape90 ? `${(mape90 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp; MAPE: {mape ? `${(mape * 100).toFixed(2)}%` : '-'}    &nbsp;&nbsp;  tradingVolume: {byTransaction?.length}</div>
          <ReactECharts
            option={option}
            opts={{
              height: 800,
              width: 800
            }}
          />
        </Box>


        <Box sx={{ width: 800, height: 900 }}>
          <Typography variant={'h5'}>AI Valuation2 Deviation By Transactions:</Typography>
          <Typography variant={'h5'}>{collection}</Typography>
          <div>MAPE70%: {mape702 ? `${(mape702 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp;MAPE80%: {mape802 ? `${(mape802 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp; MAPE90%: {mape902 ? `${(mape902 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp; MAPE: {mape2 ? `${(mape2 * 100).toFixed(2)}%` : '-'}    &nbsp;&nbsp;  tradingVolume: {byTransaction2?.length}</div>
          <ReactECharts
            option={option2}
            opts={{
              height: 800,
              width: 800
            }}
          />
        </Box>
        
        
        <Box sx={{ width: 800, height: 900 }}>
          <Typography variant={'h5'}>Simple V3 Valuation Deviation By Transactions:</Typography>
          <Typography variant={'h5'}>{collection}</Typography>
          <div>MAPE70%: {mapeV370 ? `${(mapeV370 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp;MAPE80%: {mapeV380 ? `${(mapeV380 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp; MAPE90%: {mapeV390 ? `${(mapeV390 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp; MAPE: {mapeV3 ? `${(mapeV3 * 100).toFixed(2)}%` : '-'}    &nbsp;&nbsp;  tradingVolume: {byTransactionV3?.length}</div>
          <ReactECharts
            option={optionV3}
            opts={{
              height: 800,
              width: 800
            }}
          />
        </Box>
        {/* <Box sx={{ width: 800, height: 900 }}>
          <Typography variant={'h5'}>Strategy V1 Valuation Deviation By Transactions:</Typography>
          <Typography variant={'h5'}>{collection}</Typography>
          <div>MAPE70%: {mape70StrategyV1 ? `${(mape70StrategyV1 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp;MAPE80%: {mape80StrategyV1 ? `${(mape80StrategyV1 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp; MAPE90%: {mape90StrategyV1 ? `${(mape90StrategyV1 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp; MAPE: {mapeStrategyV1 ? `${(mapeStrategyV1 * 100).toFixed(2)}%` : '-'}    &nbsp;&nbsp;  tradingVolume: {byTransactionV3?.length}</div>
          <ReactECharts
            option={optionStrategyV1}
            opts={{
              height: 800,
              width: 800
            }}
          />
        </Box>
        <Box sx={{ width: 800, height: 900 }}>
          <Typography variant={'h5'}>Strategy V2 Valuation Deviation By Transactions:</Typography>
          <Typography variant={'h5'}>{collection}</Typography>
          <div>MAPE70%: {mape70StrategyV2 ? `${(mape70StrategyV2 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp;MAPE80%: {mape80StrategyV2 ? `${(mape80StrategyV2 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp; MAPE90%: {mape90StrategyV2 ? `${(mape90StrategyV2 * 100).toFixed(2)}%`: '-'} &nbsp;&nbsp; MAPE: {mapeStrategyV2 ? `${(mapeStrategyV2 * 100).toFixed(2)}%` : '-'}    &nbsp;&nbsp;  tradingVolume: {byTransactionV3?.length}</div>
          <ReactECharts
            option={optionStrategyV2}
            opts={{
              height: 800,
              width: 800
            }}
          />
        </Box> */}
      </Box>
      
    </Box>
  )
}

const SimpleValuationDeviationByEchart: React.FC = () => {
  const { collection } = useContext(CollectionContext)
  const DATE_RANGE_QUICK_OPTIONS = [1, 3, 5, 7, 15, 30, 90]

  const [activeDateRangeQuickOption, setActiveDateRangeQuickOption] = useState<number | null>(null)
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null])

  useEffect(() => {
    if (activeDateRangeQuickOption) {
      const now = dayjs()

      setDateRange([
        now.subtract(activeDateRangeQuickOption, 'day'),
        now
      ])
    } else {
      setDateRange([null, null])
    }
  }, [activeDateRangeQuickOption])

  const query = useMemo(() => {
    const [start, end] = dateRange
    return {
      id: collection,
      startTime: start?.toDate().getTime() || 0,
      endTime: end?.toDate().getTime() || Date.now()
    }
  }, [dateRange, collection])
  
  const { data: distributions } = SimpleValuationDeviationQuery(query)
  const { data: distributionsV2 } = SimpleValuationV2DeviationQuery(query)
  const { data: distributionsV3 } = SimpleValuationV3DeviationQuery(query)

  const chartData = useMemo(() => {
    return (stepLen(100, distributions || []))
  }, [distributions])
  const names = useMemo(() => {
    return Object.keys(chartData)
  }, [chartData])
  const counts = useMemo(() => {
    return Object.values(chartData)
  }, [chartData])

  const chartDataV2 = useMemo(() => {
    return (stepLen(100, distributionsV2 || []))
  }, [distributionsV2])
  
  const namesV2 = useMemo(() => {
    return Object.keys(chartDataV2)
  }, [chartDataV2])
  const countsV2 = useMemo(() => {
    return Object.values(chartDataV2)
  }, [chartDataV2])

  const chartDataV3 = useMemo(() => {
    return (stepLen(100, distributionsV3 || []))
  }, [distributionsV3])
  const namesV3 = useMemo(() => {
    return Object.keys(chartDataV3)
  }, [chartDataV3])
  const countsV3 = useMemo(() => {
    return Object.values(chartDataV3)
  }, [chartDataV3])

  const optionV2: EChartsOption = useMemo(() => {
    return ({
      tooltip: {},
      legend: {
        data: ['Simple Valuation Distribute']
      },
      xAxis: {
        type: 'category',
        data: namesV2
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        name: 'Simple V2 Valuation Distribute count:',
        type: 'bar',
        data: countsV2
      }]
    })
  }, [namesV2, countsV2])

  const optionV3: EChartsOption = useMemo(() => {
    return ({
      tooltip: {},
      legend: {
        data: ['Simple Valuation Distribute']
      },
      xAxis: {
        type: 'category',
        data: namesV3
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        name: 'Simple V2 Valuation Distribute count:',
        type: 'bar',
        data: countsV3
      }]
    })
  }, [namesV3, countsV3])
  // const names = byTransaction?.map(o => o.name)
  // const counts = byTransaction?.map(o => o.count)
  const option: EChartsOption = useMemo(() => {
    return ({
      tooltip: {},
      legend: {
        data: ['Simple Valuation Distribute']
      },
      xAxis: {
        type: 'category',
        data: names
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        name: 'Simple Valuation Distribute count:',
        type: 'bar',
        data: counts
      }]
    })
  }, [names, counts])
  return (
    <Box sx={{ width: 1600, height: '500' }}>
      <Box sx={{ display: 'flex', justifyContent: 'end' }} m={'32px 0'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              startText="From"
              endText="To"
              value={dateRange}
              onChange={newValue => {
                setDateRange(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="date-range"
              name="date-range"
              value={activeDateRangeQuickOption}
              onChange={e => setActiveDateRangeQuickOption(+e.target.value)}
            >
              {
                DATE_RANGE_QUICK_OPTIONS.map(option => (
                  <FormControlLabel value={option} control={<Radio />} label={`In ${option} days`} key={option} />
                ))
              }
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box>
          <Typography variant={'h5'}>Simple Valuation Distribute :</Typography>
          <ReactECharts
            option={option}
            style={{ height: 400, width: 800 }}
            opts={{ renderer: 'svg' }}
          />
        </Box>
        {/* <Box>
          <Typography variant={'h5'}>Simple V2 Valuation Distribute :</Typography>
          <ReactECharts
            option={optionV2}
            style={{ height: 400, width: 800 }}
            opts={{ renderer: 'svg' }}
          />
        </Box> */}
        <Box>
          <Typography variant={'h5'}>Simple V3 Valuation Distribute :</Typography>
          <ReactECharts
            option={optionV3}
            style={{ height: 400, width: 800 }}
            opts={{ renderer: 'svg' }}
          />
        </Box>
      </Box>
    </Box>
  )
}

const SimpleValuationDeviationV2BasedOnTransactionChart: React.FC = () => {
  const { collection } = useContext(CollectionContext)
  const DATE_RANGE_QUICK_OPTIONS = [1, 3, 5, 7, 15, 30]

  const [activeDateRangeQuickOption, setActiveDateRangeQuickOption] = useState<number | null>(null)
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null])

  useEffect(() => {
    if (activeDateRangeQuickOption) {
      const now = dayjs()

      setDateRange([
        now.subtract(activeDateRangeQuickOption, 'day'),
        now
      ])
    } else {
      setDateRange([null, null])
    }
  }, [activeDateRangeQuickOption])

  const query = useMemo(() => {
    const [start, end] = dateRange
    return {
      id: collection,
      startTime: start?.toDate().getTime() || 0,
      endTime: end?.toDate().getTime() || Date.now()
    }
  }, [dateRange, collection])

  const { data: byTransaction } = SimpleValuationDeviationV2BasedOnTransactionQuery(query)
  const mape = React.useMemo(() => {
    return byTransaction ? byTransaction.reduce((r, cur) => (r + Math.abs((cur.valuationV2 - cur.salePrice) / cur.salePrice)), 0) / byTransaction.length : undefined
  }, [byTransaction])
  const maxSalePrice = useMemo(() => byTransaction?.map(o => o.salePrice).reduce((max, next) => Math.max(max, next), 0) || 0, [byTransaction])

  const linearData = useMemo(() => new Array(100).fill(undefined).map((_, i) => ([(i / 100 * maxSalePrice).toFixed(3), (i / 100 * maxSalePrice).toFixed(2)])), [maxSalePrice])

  const option: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'value',
        name: 'Sale Price'
      },
      yAxis: {
        type: 'value',
        name: 'ValuationV2'
      },
      tooltip: {
        trigger: 'item',
        hideDelay: 250,
        enterable: true,
        position: point => point,
        formatter: (props: any) => {
          if (props.componentSubType === 'scatter') {
            const data: any = props.data
            return `
              <div style="display: flex; flex-direction: column; align-items: start;">
                <div><b>NFT Name: </b>${data.name}</div>
                <br>
                <div style="width: 200px"><b>history: </b>${data.history}</div>
                <div><b>symbol: </b>${data.symbol}</div>
                <div><b>Sale Price: </b>${data.salePrice}</div>
                <div><b>ValuationV1: </b>${data.valuationV1}</div>
                <div><b>ValuationV2: </b>${data.valuationV2}</div>
                <div><b>bias: </b>${data.bias}</div>
                <div><b>strategyBias: </b>${data.strategyBias}</div>
                <div><b>rarity: </b>${data.rarity}</div>
                <div><b>rarityMedian: </b>${data.rarityMedian}</div>
                <div><b>collectionValuation: </b>${data.collectionValuation}</div>
                <div>
                  <b>Deviation: </b>
                  <span style="color: ${data.valuation < data.salePrice ? 'green' : 'red'}">${((data.valuationV2 - data.salePrice) / data.salePrice * 100).toFixed(2)}%</span>
                </div>
                <div><b>Transaction Program: </b>${data.program}</div>
                <div><b>Transaction Time: </b>${dayjs(data.transactionTime * 1000).format('YYYY/MM/DD HH:mm:ss')}</div>
                <a href="https://solscan.io/tx/${data.transactionSignature}" target="_blank" rel="noreferrer">View transaction on Solscan</a>
              </div>
            `
          }

          return ''
        }
      },
      dataset: [
        {
          source: byTransaction || []
        }
      ],
      series: [
        {
          type: 'line',
          name: 'Perfect Model',
          data: linearData,
          symbol: 'false'
        },
        {
          type: 'scatter',
          name: 'Valuation',
          datasetIndex: 0,
          itemStyle: {
            color(params) {
              const data = JSON.parse(JSON.stringify(params.data))
              if (Math.abs(data.valuationV2 - data.salePrice) > Math.abs(data.valuationV1 - data.salePrice)) {
                return 'red'
              }
              return 'green'
            },
          },
          encode: {
            x: 'salePrice',
            y: 'valuationV2'
          }
        },
      ],
      legend: {
        data: ['Perfect Model', 'Valuation']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [byTransaction])

  return (
    <Box sx={{ width: 1600, height: '1030px' }}>
      <Typography variant={'h5'}>Simple V2 Valuation Deviation By Transactions:</Typography>
      <div>MAPE: {mape ? `${mape * 100}%` : '-'} &nbsp;&nbsp;  tradingVolume: {byTransaction?.length}</div>
      <Box sx={{ display: 'flex', justifyContent: 'end' }} m={'32px 0'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              startText="From"
              endText="To"
              value={dateRange}
              onChange={newValue => {
                setDateRange(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="date-range"
              name="date-range"
              value={activeDateRangeQuickOption}
              onChange={e => setActiveDateRangeQuickOption(+e.target.value)}
            >
              {
                DATE_RANGE_QUICK_OPTIONS.map(option => (
                  <FormControlLabel value={option} control={<Radio />} label={`In ${option} days`} key={option} />
                ))
              }
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <ReactECharts
        option={option}
        opts={{
          height: 800
        }}
      />
    </Box>
  )
}

const SimpleValuationDeviationV2ByEchart: React.FC = () => {
  const { collection } = useContext(CollectionContext)
  const DATE_RANGE_QUICK_OPTIONS = [1, 3, 5, 7, 15, 30]

  const [activeDateRangeQuickOption, setActiveDateRangeQuickOption] = useState<number | null>(null)
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null])

  useEffect(() => {
    if (activeDateRangeQuickOption) {
      const now = dayjs()

      setDateRange([
        now.subtract(activeDateRangeQuickOption, 'day'),
        now
      ])
    } else {
      setDateRange([null, null])
    }
  }, [activeDateRangeQuickOption])

  const query = useMemo(() => {
    const [start, end] = dateRange
    return {
      id: collection,
      startTime: start?.toDate().getTime() || 0,
      endTime: end?.toDate().getTime() || Date.now()
    }
  }, [dateRange, collection])
  
  const { data: distributions } = SimpleValuationV2DeviationQuery(query)
  const chartData = useMemo(() => {
    if (!distributions) return {}
    const min = Math.min(...distributions)
    const max = Math.max(...distributions)
    const stepLen = (max - min) / 100
    
    const getLevel = (item: number) => {
      for (let i = 0; i * stepLen + min <= max; i++) {
        if (min + stepLen * i <= item && item <= min + stepLen * (i + 1)) {
          return ((min + stepLen * i) * 100).toFixed(4)
        }
      }
    }

    return distributions.reduce<Record<string, number>>((res, next) => {
      const level = getLevel(next) + '%'
      if (!level) {
        return res
      }

      const cnt = res[level] || 0

      return {
        ...res,
        [level]: cnt + 1
      }
    }, {})
  }, [distributions])
  const names = useMemo(() => {
    return Object.keys(chartData)
  }, [chartData])
  const counts = useMemo(() => {
    return Object.values(chartData)
  }, [chartData])
  // const names = byTransaction?.map(o => o.name)
  // const counts = byTransaction?.map(o => o.count)
  const option: EChartsOption = useMemo(() => {
    return ({
      tooltip: {},
      legend: {
        data: ['Simple Valuation Distribute']
      },
      xAxis: {
        type: 'category',
        data: names
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        name: 'Simple Valuation Distribute count:',
        type: 'bar',
        data: counts
      }]
    })
  }, [names, counts])
  return (
    <Box sx={{ width: 1600, height: '500' }}>
      <Typography variant={'h5'}>Simple V2 Valuation Distribute :</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'end' }} m={'32px 0'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              startText="From"
              endText="To"
              value={dateRange}
              onChange={newValue => {
                setDateRange(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="date-range"
              name="date-range"
              value={activeDateRangeQuickOption}
              onChange={e => setActiveDateRangeQuickOption(+e.target.value)}
            >
              {
                DATE_RANGE_QUICK_OPTIONS.map(option => (
                  <FormControlLabel value={option} control={<Radio />} label={`In ${option} days`} key={option} />
                ))
              }
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <ReactECharts
        option={option}
        style={{ height: 400 }}
        opts={{ renderer: 'svg' }}
      />
    </Box>
  )
}

const ValuationDeviationBasedOnTransactionChart: React.FC = () => {
  const { collection } = useContext(CollectionContext)
  const DATE_RANGE_QUICK_OPTIONS = [1, 3, 5, 7, 15, 30, 90]

  const [activeDateRangeQuickOption, setActiveDateRangeQuickOption] = useState<number | null>(null)
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null])

  useEffect(() => {
    if (activeDateRangeQuickOption) {
      const now = dayjs()

      setDateRange([
        now.subtract(activeDateRangeQuickOption, 'day'),
        now
      ])
    } else {
      setDateRange([null, null])
    }
  }, [activeDateRangeQuickOption])

  const query = useMemo(() => {
    const [start, end] = dateRange

    return {
      id: collection,
      startTime: start?.toDate().getTime() || 0,
      endTime: end?.toDate().getTime() || Date.now()
    }
  }, [dateRange, collection])
  
  const { data: byTransaction } = useValuationDeviationBasedOnTransactionQuery(query)
  const mape = React.useMemo(() => {
    return byTransaction ? byTransaction.reduce((r, cur) => (r + Math.abs((cur.valuation - cur.salePrice) / cur.salePrice)), 0) / byTransaction.length : undefined
  }, [byTransaction])

  const maxSalePrice = useMemo(() => byTransaction?.map(o => o.salePrice).reduce((max, next) => Math.max(max, next), 0) || 0, [byTransaction])

  const linearData = useMemo(() => new Array(100).fill(undefined).map((_, i) => ([(i / 100 * maxSalePrice).toFixed(3), (i / 100 * maxSalePrice).toFixed(2)])), [maxSalePrice])

  const option: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'value',
        name: 'Sale Price'
      },
      yAxis: {
        type: 'value',
        name: 'Valuation'
      },
      tooltip: {
        trigger: 'item',
        hideDelay: 250,
        enterable: true,
        position: point => point,
        formatter: (props: any) => {
          if (props.componentSubType === 'scatter') {
            const data: ValuationDeviationBasedOnTransaction = props.data

            return `
              <div style="display: flex; flex-direction: column; align-items: start;">
                <div><b>NFT Name: </b>${data.name}</div>
                <br>
                <div><b>Sale Price: </b>${data.salePrice}</div>
                <div><b>Valuation: </b>${data.valuation}</div>
                <div>
                  <b>Deviation: </b>
                  <span style="color: ${data.valuation < data.salePrice ? 'green' : 'red'}">${((data.valuation - data.salePrice) / data.salePrice * 100).toFixed(2)}%</span>
                </div>
                <div><b>Transaction Program: </b>${data.program}</div>
                <div><b>Transaction Time: </b>${dayjs(data.transactionTime * 1000).format('YYYY/MM/DD HH:mm:ss')}</div>
                <a href="https://solscan.io/tx/${data.transactionSignature}" target="_blank" rel="noreferrer">View transaction on Solscan</a>
              </div>
            `
          }

          return ''
        }
      },
      dataset: [
        {
          source: byTransaction || []
        }
      ],
      series: [
        {
          type: 'line',
          name: 'Perfect Model',
          data: linearData,
          symbol: 'false'
        },
        {
          type: 'scatter',
          name: 'Valuation',
          datasetIndex: 0,
          encode: {
            x: 'salePrice',
            y: 'valuation'
          }
        },
      ],
      legend: {
        data: ['Perfect Model', 'Valuation']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [byTransaction])

  return (
    <Box sx={{ width: 1600, height: '1030px' }}>
      <Typography variant={'h5'}>AI Valuation Deviation By Transactions:</Typography>
      <div>MAPE: {mape ? `${mape * 100}%` : '-'} &nbsp;&nbsp;  tradingVolume: {byTransaction?.length}</div>
      <Box sx={{ display: 'flex', justifyContent: 'end' }} m={'32px 0'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              startText="From"
              endText="To"
              value={dateRange}
              onChange={newValue => {
                setDateRange(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="date-range"
              name="date-range"
              value={activeDateRangeQuickOption}
              onChange={e => setActiveDateRangeQuickOption(+e.target.value)}
            >
              {
                DATE_RANGE_QUICK_OPTIONS.map(option => (
                  <FormControlLabel value={option} control={<Radio />} label={`In ${option} days`} key={option} />
                ))
              }
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <ReactECharts
        option={option}
        opts={{
          height: 800
        }}
      />
    </Box>
  )
}

const ValuationDistributionChart: React.FC = () => {
  const { collection } = useContext(CollectionContext)
  
  const DATE_RANGE_QUICK_OPTIONS = [1, 3, 5, 7, 15, 30, 90]

  const [activeDateRangeQuickOption, setActiveDateRangeQuickOption] = useState<number | null>(null)
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null])

  useEffect(() => {
    if (activeDateRangeQuickOption) {
      const now = dayjs()

      setDateRange([
        now.subtract(activeDateRangeQuickOption, 'day'),
        now
      ])
    } else {
      setDateRange([null, null])
    }
  }, [activeDateRangeQuickOption])

  const query = useMemo(() => {
    const [start, end] = dateRange

    return {
      id: collection,
      startTime: start?.toDate().getTime() || 0,
      endTime: end?.toDate().getTime() || Date.now()
    }
  }, [dateRange, collection])
  const { data: distributions } = useAllValuationsQuery(query)
  const chartData = useMemo(() => {
    if (!distributions) return {}
    const min = Math.min(...distributions)
    const max = Math.max(...distributions)
    const stepLen = (max - min) / 256
    
    const getLevel = (item: number) => {
      for (let i = 0; i * stepLen + min <= max; i++) {
        if (min + stepLen * i <= item && item <= min + stepLen * (i + 1)) {
          return (min + stepLen * i).toFixed(4)
        }
      }
    }

    return distributions.reduce<Record<string, number>>((res, next) => {
      const level = getLevel(next)
      if (!level) {
        return res
      }

      const cnt = res[level] || 0

      return {
        ...res,
        [level]: cnt + 1
      }
    }, {})
  }, [distributions])
  
  const option: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'value',
        name: 'Valuation Range',
        min: 'dataMin',
        max: 'dataMax'
      },
      yAxis: {
        type: 'value',
        name: 'Count'
      },
      tooltip: {
        trigger: 'axis',
      },
      dataset: [
        {
          source: Object.entries(chartData)
        }
      ],
      series: [
        {
          type: 'bar',
          name: 'Valuation Distributions',
          symbol: 'false',
          encode: {
            x: 0,
            y: 1
          }
        },
      ],
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [chartData])

  return (
    <Box sx={{ width: 1600, height: '750px' }}>
      <Typography variant={'h5'}>Valuation Distribution:</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'end' }} m={'32px 0'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              startText="From"
              endText="To"
              value={dateRange}
              onChange={newValue => {
                setDateRange(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="date-range"
              name="date-range"
              value={activeDateRangeQuickOption}
              onChange={e => setActiveDateRangeQuickOption(+e.target.value)}
            >
              {
                DATE_RANGE_QUICK_OPTIONS.map(option => (
                  <FormControlLabel value={option} control={<Radio />} label={`In ${option} days`} key={option} />
                ))
              }
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <ReactECharts
        option={option}
        opts={{
          height: 500
        }}
      />
    </Box>
  )
}

const SalePriceDistributionChart: React.FC = () => {
  const { collection } = useContext(CollectionContext)
  

  const DATE_RANGE_QUICK_OPTIONS = [1, 3, 5, 7, 15, 30, 90]

  const [activeDateRangeQuickOption, setActiveDateRangeQuickOption] = useState<number | null>(null)
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null])

  useEffect(() => {
    if (activeDateRangeQuickOption) {
      const now = dayjs()

      setDateRange([
        now.subtract(activeDateRangeQuickOption, 'day'),
        now
      ])
    } else {
      setDateRange([null, null])
    }
  }, [activeDateRangeQuickOption])

  const query = useMemo(() => {
    const [start, end] = dateRange

    return {
      id: collection,
      startTime: start?.toDate().getTime() || 0,
      endTime: end?.toDate().getTime() || Date.now()
    }
  }, [dateRange, collection])

  const { data: distributions } = useAllSalePricesQuery(query)

  const chartData = useMemo(() => {
    if (!distributions) return {}
    const min = Math.min(...distributions)
    const max = Math.max(...distributions)
    const stepLen = (max - min) / 256

    const getLevel = (item: number) => {
      for (let i = 0; i * stepLen + min <= max; i++) {
        if (min + stepLen * i <= item && item <= min + stepLen * (i + 1)) {
          return (min + stepLen * i).toFixed(4)
        }
      }
    }

    return distributions.reduce<Record<string, number>>((res, next) => {
      const level = getLevel(next)
      if (!level) {
        return res
      }

      const cnt:any = res[level] || 0

      return {
        ...res,
        [level]: cnt + 1
      }
    }, {})
  }, [distributions])

  const option: EChartsOption = useMemo(() => {
    return ({
      xAxis: {
        type: 'value',
        name: 'SalePrice',
        min: 'dataMin',
        max: 'dataMax'
      },
      yAxis: {
        type: 'value',
        name: 'Count'
      },
      tooltip: {
        trigger: 'axis',
      },
      dataset: [
        {
          source: Object.entries(chartData)
        }
      ],
      series: [
        {
          type: 'bar',
          name: 'SalePrice Distributions',
          symbol: 'false',
          encode: {
            x: 0,
            y: 1
          }
        },
      ],
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        },
        {
          yAxisIndex: 0,
          start: 0,
          end: 100
        }
      ],
    })
  }, [chartData])

  return (
    <Box sx={{ width: 1600, height: '750px' }}>
      <Typography variant={'h5'}>SalePrice Distribution:</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'end' }} m={'32px 0'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              startText="From"
              endText="To"
              value={dateRange}
              onChange={newValue => {
                setDateRange(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="date-range"
              name="date-range"
              value={activeDateRangeQuickOption}
              onChange={e => setActiveDateRangeQuickOption(+e.target.value)}
            >
              {
                DATE_RANGE_QUICK_OPTIONS.map(option => (
                  <FormControlLabel value={option} control={<Radio />} label={`In ${option} days`} key={option} />
                ))
              }
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <ReactECharts
        option={option}
        opts={{
          height: 500
        }}
      />
    </Box>
  )
}

const AiValuationDeviationByEchart: React.FC = () => {
  const { collection } = useContext(CollectionContext)
  const DATE_RANGE_QUICK_OPTIONS = [1, 3, 5, 7, 15, 30, 90]

  const [activeDateRangeQuickOption, setActiveDateRangeQuickOption] = useState<number | null>(null)
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null])

  useEffect(() => {
    if (activeDateRangeQuickOption) {
      const now = dayjs()

      setDateRange([
        now.subtract(activeDateRangeQuickOption, 'day'),
        now
      ])
    } else {
      setDateRange([null, null])
    }
  }, [activeDateRangeQuickOption])

  const query = useMemo(() => {
    const [start, end] = dateRange
    return {
      id: collection,
      startTime: start?.toDate().getTime() || 0,
      endTime: end?.toDate().getTime() || Date.now()
    }
  }, [dateRange, collection])
  
  const { data: distributions } = AiValuationDeviationQuery(query)
  const chartData = useMemo(() => {
    if (!distributions) return {}
    const min = Math.min(...distributions)
    const max = Math.max(...distributions)
    const stepLen = (max - min) / 100
    
    const getLevel = (item: number) => {
      for (let i = 0; i * stepLen + min <= max; i++) {
        if (min + stepLen * i <= item && item <= min + stepLen * (i + 1)) {
          return ((min + stepLen * i) * 100).toFixed(4)
        }
      }
    }

    return distributions.reduce<Record<string, number>>((res, next) => {
      const level = getLevel(next) + '%'
      if (!level) {
        return res
      }

      const cnt = res[level] || 0

      return {
        ...res,
        [level]: cnt + 1
      }
    }, {})
  }, [distributions])
  const names = useMemo(() => {
    return Object.keys(chartData)
  }, [chartData])
  const counts = useMemo(() => {
    return Object.values(chartData)
  }, [chartData])
  // const names = byTransaction?.map(o => o.name)
  // const counts = byTransaction?.map(o => o.count)
  const option: EChartsOption = {
    tooltip: {},
    legend: {
      data: ['AI Valuation Distribute']
    },
    xAxis: {
      type: 'category',
      data: names
    },
    yAxis: {
      type: 'value'
    },
    series: [{
      name: 'AI Valuation Distribute count:',
      type: 'bar',
      data: counts
    }]
  }
  return (
    <Box sx={{ width: 1600, height: '500' }}>
      <Typography variant={'h5'}>AI Valuation Distribute :</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'end' }} m={'32px 0'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              startText="From"
              endText="To"
              value={dateRange}
              onChange={newValue => {
                setDateRange(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="date-range"
              name="date-range"
              value={activeDateRangeQuickOption}
              onChange={e => setActiveDateRangeQuickOption(+e.target.value)}
            >
              {
                DATE_RANGE_QUICK_OPTIONS.map(option => (
                  <FormControlLabel value={option} control={<Radio />} label={`In ${option} days`} key={option} />
                ))
              }
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <ReactECharts
        option={option}
        style={{ height: 400 }}
        opts={{ renderer: 'svg' }}
      />
    </Box>
  )
}

const ValuationDeviationRankTable: React.FC = () => {
  const { data: allDeviations } = useAllValuationDeviationsQuery()
  const { data: whitelist } = useWhitelistQuery()
  const { setCollection } = useContext(CollectionContext)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const analysisByCollection: Array<Whitelist & { deviationAvg?: number, deviations: Array<ValuationDeviationByDailyTrend> }> = useMemo(() => {
    if (!allDeviations || !whitelist) return []

    const arr = whitelist.map(o => {
      const deviations = allDeviations.filter(d => d.slugSymbol === o.id)

      if (o.id === 'VISIONARYSTUDIOS') {
        console.log(o.id, deviations)
      }

      const deviationAvgs = deviations.map(d => d.avg)

      return {
        ...o,
        id: o.id,
        deviations,
        deviationAvg: avgOf(deviationAvgs)
      }
    })

    arr.sort((a, b) => {
      const da = a.deviationAvg || Infinity
      const db = b.deviationAvg || Infinity
      return da - db
    })

    return arr
  }, [allDeviations, whitelist])

  const dataToRender = useMemo(() => {
    return analysisByCollection?.slice(page * rowsPerPage, (page + 1) * rowsPerPage) || []
  }, [page, rowsPerPage, analysisByCollection])

  return (
    <Box width={1600} mb={'64px'}>
      <TableContainer sx={{ maxHeight: 800 }}>
        <Table sx={{ width: '100%', overflowY: 'scroll' }}>
          <TableHead>
            <TableRow>
              <TableCell>Collection</TableCell>
              <TableCell align="center">Slug Symbol</TableCell>
              <TableCell align="center">Deviation Avg</TableCell>
              <TableCell align="center">Valuation Supported</TableCell>
              <TableCell align="center">Analysis Supported</TableCell>
              <TableCell align="center">Deviation(mape) <br /> Daily Trend</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              dataToRender.map(row => (
                <TableRow
                  key={row.nftName}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Button variant={'text'} onClick={() => setCollection(row.id)}>
                      {row.nftName}
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    {row.id}
                  </TableCell>
                  <TableCell align="center">
                    {row.deviationAvg ? `${(row.deviationAvg * 100).toFixed(2)}%` : '-'}
                  </TableCell>
                  <TableCell align={'center'} width={'160px'}>
                    <MiniSizeValuationDeviationByDailyTrendChart deviations={row.deviations} />
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        colSpan={3}
        rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
        count={analysisByCollection.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, page) => setPage(page)}
        onRowsPerPageChange={event => {
          setRowsPerPage(parseInt(event.target.value, 10))
          setPage(0)
        }}
      />
    </Box>
  )
}

export const SolanaAnalytics = () => {
  const { collection, setCollection } = useContext(CollectionContext)
  const { setSymbol } = useContext(CollectionContext)

  const { data: whitelist } = useWhitelistQuery()

  const options = useMemo(() => whitelist?.map(o => ({ ...o, label: o.nftName })) || [], [whitelist])

  return (
    <section>
      <Box sx={{ position: 'fixed', left: '30px', top: '30%', width: '320px' }}>
        <FormControl fullWidth>
          <Autocomplete
            value={options?.find(o => o.id === collection) || null}
            disablePortal
            id={'Slug-symbol'}
            options={options}
            getOptionLabel={option => `${option.nftName}(${option.symbol})-${option.priceType}`}
            renderInput={params => <TextField {...params} label="Collection" />}
            onChange={(_e, value) => {
              setCollection(value?.id || '')
              setSymbol(value?.symbol || '')
            }}
          />
        </FormControl>
      </Box>

      <Card sx={{ padding: '16px', marginBottom: '48px' }}>
        {/* <ValuationDeviationRankTable /> */}
        <ValuationDeviationByDailyTrendChart />
        <SimpleValuationDeviationBasedOnTransactionChart />
        <SimpleValuationDeviationByEchart />
        {/* <SimpleValuationDeviationV2BasedOnTransactionChart /> */}
        {/* <SimpleValuationDeviationV2ByEchart /> */}
        <ValuationDeviationBasedOnTransactionChart />
        <AiValuationDeviationByEchart />
        <ValuationDistributionChart />
        <SalePriceDistributionChart />
      </Card>
    </section>
  )
}
